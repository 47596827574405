<template>
    <span
        :class="`ki-${currentIconType} ki-${props.iconName}${
            props.iconClass ? ' ' + props.iconClass : ''
        }`">
        <template v-if="icons[props.iconName] && currentIconType === 'duotone'">
            <span
                v-for="i in icons[props.iconName]"
                :class="`path${i}`"></span>
        </template>
    </span>
</template>

<script setup lang="ts">
import { computed } from "vue"
import iconList from "@/helpers/kt-icon/icons.json"
import { useConfigStore } from "@/store/config"

interface Icon {
    [key: string]: number
}

const icons: Icon = iconList

const store = useConfigStore()

const props = defineProps({
    iconName: {
        type: String,
        default: ``,
        required: true
    },
    iconType: {
        type: String,
        default: ``,
        required: false
    },
    iconClass: {
        type: String,
        default: ``,
        required: false
    }
})

const currentIconType = computed(() => {
    return props.iconType ? props.iconType : store.config.general.iconsType
})
</script>
